import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useMedia } from '@src/lib/hooks';

import Layout from '@components/layout';

import BodyText from '@components/bodyText';
import Contact from '../../components/contact';
import Hero from '../../components/hero';

import LanaContainer from './lanaContainer';

export default function Komplettering({ data }) {
  const bodyText = data.contentfulPage.pageContent[0].bodyText;
  const title = data.contentfulPage.headLine;
  const isMobile = useMedia(['(max-width: 768px)'], [true], false);
  return (
    <Layout path="/lana/klar" newStyle>
      <Hero title={title} />
      <LanaContainer padded={isMobile}>
        <BodyText bodyText={bodyText} />
      </LanaContainer>
      <Contact />
    </Layout>
  );
}

Komplettering.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query KompletteringPageQuery {
    contentfulPage(pageUrl: { eq: "/lana/kompletteringskrav" }) {
      headLine
      pageContent {
        ... on ContentfulDescriptionComponent {
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
